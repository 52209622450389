.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading-container{
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.alignCenter{
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  width: 100%
}
.assign-more-btn{
  margin-bottom: 15px;
}
.center-center{
  display: flex;
    align-items: center;
    justify-content: center;
}
.export_btn{
  color: blue;
  padding-left: 5px;
  padding-top: 10px;
}
.edit-profile-divider-text{
  font-size: 18px;
  line-height: 22px;
}
.filter-input-item{
  padding: 0px 8px;
  height: 30px;
  background-color: #82a1e9;
}
legend{
  width: fit-content!important;
}
.police-field{
  border: 1px solid grey;
  border-radius: 5px;
  padding: 20px;
  height: fit-content;
  max-height: 320px;
  overflow-y: auto;
}
.assign-role-field{
  border: 1px solid grey;
  border-radius: 5px;
  padding: 20px;
  height: fit-content;
  max-height: 600px;
  overflow-y: auto;
}
.user-field{
  border: 1px solid grey;
  border-radius: 5px;
  padding: 20px;
  height: fit-content;
}
.user-field span.inputbox{
  padding: 4px 11px!important;
  margin-bottom: 3px;
}
.ant-input .ant-select-selector {
  height: 33px!important;
}
.drop-userinfo{
  padding-left: 20px;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #0f54ef!important;
  color: white!important;
}
.line-drag{
  border-color: red!important;
  border-width: 8px!important;
}

/* role manager page */

.role-title{
  font-size: 20px;
  font-weight: bold;
}
.policy-editor-roloes{
  height: auto;
  max-height: 60vh;
  overflow-y: auto;
}
.spin-color-report{
  color: white;
}